var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', {
    staticClass: "sidebar-content card show"
  }, [_vm.newChat ? _c('div', {
    staticClass: "cntcs"
  }, [_c('b-button', {
    staticClass: "btn-icon mr-1",
    attrs: {
      "variant": "flat-success"
    },
    on: {
      "click": _vm.onClickBack
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ArrowLeftIcon"
    }
  })], 1), _c('h4', {
    staticClass: "m-0"
  }, [_vm._v("Contactos")])], 1) : _c('b-button', {
    staticClass: "mx-1 mb-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.onClickNewChat
    }
  }, [_vm._v(" Nuevo chat ")]), _vm.newChat ? _c('vue-perfect-scrollbar', {
    staticClass: "chat-user-list-wrapper list-group scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge ml-1",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "text-muted",
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "Buscar..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _vm.isLoading ? _c('div', {
    staticClass: "ldr"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('ul', {
    staticClass: "chat-users-list contact-list media-list"
  }, _vm._l(_vm.filteredContacts, function (contact) {
    return _c('chat-contact', {
      key: contact.idUsuario,
      attrs: {
        "tag": "li",
        "user": contact
      },
      on: {
        "click": function click(event) {
          event.preventDefault();
          _vm.$emit('open-chat', contact);
        }
      }
    });
  }), 1)], 1) : _c('vue-perfect-scrollbar', {
    staticClass: "chat-user-list-wrapper list-group scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('ul', {
    staticClass: "chat-users-list chat-list media-list"
  }, _vm._l(_vm.filteredChatsContacts, function (contact) {
    return _c('chat-contact', {
      key: contact.idmensaje,
      class: {
        active: _vm.activeChatContactId === contact.idusuario
      },
      attrs: {
        "tag": "li",
        "user": contact,
        "is-chat-contact": ""
      },
      on: {
        "click": function click(event) {
          event.preventDefault();
          _vm.$emit('open-chat', contact);
        }
      }
    });
  }), 1)])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }