var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-container d-flex content align-items-center"
  }, [_c('ul', {
    staticClass: "nav navbar-nav d-xl-none"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('b-link', {
    staticClass: "nav-link",
    on: {
      "click": _vm.toggleVerticalMenuActive
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MenuIcon",
      "size": "21"
    }
  })], 1)], 1)]), _vm.isDashboard && _vm.isSuperUser() ? _c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    on: {
      "change": _vm.onChangeType
    },
    model: {
      value: _vm.isMarket,
      callback: function callback($$v) {
        _vm.isMarket = $$v;
      },
      expression: "isMarket"
    }
  }, [_vm._v(" Market ")]) : _vm._e(), _vm.showStoreSelect ? _c('div', {
    staticClass: "storeSelect"
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  }) : _vm._e(), !_vm.loading && _vm.storesAllowed.length > 1 ? _c('v-select', {
    attrs: {
      "label": "nombre",
      "options": _vm.allStoresAllowed,
      "clearable": false
    },
    on: {
      "option:selected": _vm.onChangeStore
    },
    model: {
      value: _vm.storeSelected,
      callback: function callback($$v) {
        _vm.storeSelected = $$v;
      },
      expression: "storeSelected"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.showWarehouseSelect ? _c('div', {
    staticClass: "storeSelect"
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  }) : _vm._e(), !_vm.loading && _vm.warehousesAllowed.length > 1 ? _c('v-select', {
    attrs: {
      "label": "nombre",
      "options": _vm.allWarehousesAllowed,
      "clearable": false
    },
    on: {
      "option:selected": _vm.onChangeWarehouse
    },
    model: {
      value: _vm.warehouseSelected,
      callback: function callback($$v) {
        _vm.warehouseSelected = $$v;
      },
      expression: "warehouseSelected"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('b-navbar-nav', {
    staticClass: "nav align-items-center ml-auto"
  }, [_c('dark-Toggler', {
    staticClass: "d-none d-lg-block"
  }), _c('chat'), _c('notification-dropdown'), _c('cart-dropdown'), _c('user-dropdown')], 1), _vm.listenSocketMarket ? _c('socket-market') : _vm._e(), _vm.listenSocketEats ? _c('socket-empresa-asociada') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }