<template>
  <div></div>
</template>

<script>
// import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserID } from "@/auth/utils";
const soundNotification = new Audio(require("../../assets/sounds/pop.mp3"));
const body = document.body;
const defaultClickEvent = (e) => {};

export default {
  components: {
    ToastificationContent,
  },
  props: {
    onNewMessage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userID: 0,
      socket: null,
      unwatch: null,
      retryTimeout: null,
    };
  },
  beforeMount() {
    this.userID = getUserID();
  },
  mounted() {
    body.addEventListener("click", defaultClickEvent);
    body.click();
    this.socketConnection();
  },
  beforeDestroy() {
    body.removeEventListener("click", defaultClickEvent);
    this.socket && this.socket.close(3999, "ComponentDestroy");
  },
  methods: {
    socketConnection() {
      if (this.socket === null) {
        this.socket = new WebSocket(this.$store.state.app.socketURL);
        this.socket.onopen = this.onSocketOpen;
        this.socket.onclose = this.onSocketClose;
        this.socket.onmessage = this.onNewNotification;
      }
    },
    onSocketOpen(event) {
      this.sendMessage(
        JSON.stringify({
          event: "/CHAT/CONNECT",
          payload: { userID: this.userID },
        })
      );
    },
    onSocketClose(event) {
      if (event.code === 3999 || event.code === 4000) {
        // TODO: Do nothing or handle
      } else {
        this.socket = null;
        this.retryTimeout = setTimeout(this.socketConnection, 10000);
      }
    },
    onNewNotification(event) {
      const message = JSON.parse(event.data);
      soundNotification.muted = false;
      soundNotification.play();
      this.onNewMessage(message.payload);
    },
    sendMessage(message) {
      this.socket.send(message);
    },
  },
};
</script>
