<template>
  <div>
    <b-nav-item @click="onClickChat">
      <feather-icon
        :badge="newMessages"
        badge-classes="bg-danger"
        class="text-body"
        icon="MessageCircleIcon"
        size="21"
      />
    </b-nav-item>

    <b-modal
      size="lg"
      title="Chats"
      v-model="showChat"
      centered
      @hidden="onCloseModal"
      hide-footer
      modal-class="chat"
    >
      <div v-if="loading" class="loader">
        <b-spinner />
      </div>
      <div v-else class="chat-application">
        <chat-left-sidebar
          @open-chat="openChatOfContact"
          :chats-contacts="chatsContacts"
          :profile-user-data="profileUserData"
          :active-chat-contact-id="
            activeChatContact ? activeChatContact.id : null
          "
          :profile-user-minimal-data="profileUserDataMinimal"
        />

        <!-- Main Area -->
        <section class="chat-app-window">
          <!-- Start Chat Logo -->
          <div v-if="!activeChatContact" class="start-chat-area">
            <div class="mb-1 start-chat-icon">
              <feather-icon icon="MessageSquareIcon" size="56" />
            </div>
          </div>

          <!-- Chat Content -->
          <div v-else class="active-chat">
            <!-- Chat Navbar -->
            <div class="chat-navbar">
              <header class="chat-header">
                <!-- Avatar & Name -->
                <div class="d-flex align-items-center">
                  <!-- Toggle Icon -->
                  <div class="sidebar-toggle d-block d-lg-none mr-1"></div>

                  <b-avatar
                    size="36"
                    class="mr-1 cursor-pointer badge-minimal"
                    badge
                  />
                  <h6 class="mb-0">
                    {{ activeChatContact.nombre }}
                    {{ activeChatContact.apellido }}
                  </h6>
                </div>
              </header>
            </div>

            <!-- User Chat Area -->
            <vue-perfect-scrollbar
              ref="refChatLogPS"
              :settings="perfectScrollbarSettings"
              class="user-chats scroll-area"
            >
              <chat-log :chat-data="activeChat" :senderId="senderID" />
            </vue-perfect-scrollbar>

            <!-- Message Input -->
            <div class="chat-app-form">
              <b-input-group class="input-group-merge form-send-message mr-1">
                <b-form-input
                  v-model="chatInputMessage"
                  placeholder="Escribe tu mensaje aqui"
                />
              </b-input-group>
              <b-button
                @click="sendMessage"
                variant="primary"
                :disabled="isSending"
              >
                Enviar
              </b-button>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
    <socket :onNewMessage="onNewMessage" />
  </div>
</template>

<script>
const CHAT_APP_STORE_MODULE_NAME = "app-chat";

import store from "@/store";
import {
  BForm,
  BModal,
  BButton,
  BAvatar,
  BSpinner,
  BNavItem,
  BDropdown,
  BFormInput,
  BInputGroup,
  BDropdownItem,
} from "bootstrap-vue";
import Socket from "../socket/Chat.vue";
import ChatLog from "./ChatLog.vue";
import { getUserID } from "@/auth/utils";
import chatStoreModule from "./chatStoreModule";
import ChatLeftSidebar from "./ChatLeftSidebar.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BForm,
    BModal,
    Socket,
    BButton,
    BAvatar,
    ChatLog,
    BSpinner,
    BNavItem,
    BDropdown,
    BFormInput,
    BInputGroup,
    BDropdownItem,
    ChatLeftSidebar,
    VuePerfectScrollbar,
  },
  data() {
    return {
      loading: true,
      showChat: false,
      senderID: getUserID(),
      isLoading: true,
      isSending: false,
      activeChat: null,
      newMessages: 0,
      chatsContacts: [],
      middlewareURL: this.$store.state.app.middlewareURL,
      profileUserData: {},
      keyEventListener: null,
      chatInputMessage: "",
      activeChatContact: null,
      profileUserDataMinimal: {},
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    };
  },
  mounted() {
    const self = this;
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME))
      store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule);
    setTimeout(() => {
      this.fetchChats();
    }, 6000);
    this.keyEventListener = (event) => {
      if (event.key === "Enter" && self.showChat) {
        self.sendMessage(event);
      }
    };
    document.addEventListener("keyup", this.keyEventListener);
  },
  beforeDestroy() {
    if (store.hasModule(CHAT_APP_STORE_MODULE_NAME))
      store.unregisterModule(CHAT_APP_STORE_MODULE_NAME);
    document.removeEventListener("keyup", this.keyEventListener);
  },
  methods: {
    scrollToBottomInChatLog() {
      this.$nextTick(() => {
        const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS;
        scrollEl.scrollTop = scrollEl.scrollHeight;
      });
    },
    fetchChats() {
      store
        .dispatch("app-chat/fetchChats", {
          middlewareURL: this.middlewareURL,
        })
        .then((response) => {
          this.loading = false;
          this.chatsContacts = response.chatconversaciones;
        });
    },
    openChatOfContact(contact) {
      const userID = contact.idUsuario ?? contact.idusuario;
      this.activeChat = null;
      this.chatInputMessage = "";
      this.activeChatContact = { id: userID, ...contact };
      store
        .dispatch("app-chat/getChat", {
          userId: userID,
          middlewareURL: this.middlewareURL,
        })
        .then((response) => {
          response.usuario[0].id = userID;
          response.usuario[0].IDConversacion = response.iDtemp;
          this.activeChat = response.chat;
          this.activeChatContact = response.usuario[0];
          this.$nextTick(() => {
            this.scrollToBottomInChatLog();
          });
        });
    },
    onNewMessage(message) {
      const lastIndex = message.chat.length - 1;
      if (
        this.activeChatContact &&
        this.activeChatContact.id === message.chat[lastIndex].idusuario
      ) {
        this.activeChat.push(message.lsmensaje[0]);
        this.$nextTick(() => {
          this.scrollToBottomInChatLog();
        });
      } else {
        this.newMessages += 1;
        const newMsj = message.chat[lastIndex];
        const contact = this.chatsContacts.find(
          (c) => c.idusuario === newMsj.idusuario
        );
        if (contact) contact.mensaje = newMsj.mensaje;
        else this.chatsContacts.unshift(newMsj);
      }
    },
    sendMessage(event) {
      event && event.preventDefault();
      if (!this.chatInputMessage) return;
      this.isSending = true;
      const payload = {
        message: this.chatInputMessage,
        IDUsuarioChat: this.activeChatContact.id,
        middlewareURL: this.middlewareURL,
        IDConversacion: this.activeChatContact.IDConversacion,
      };
      store.dispatch("app-chat/sendMessage", payload).then((response) => {
        this.isSending = false;
        if (response.bOk) {
          const newMsj = {
            id: response.usuario.id,
            nombre: this.activeChatContact.nombre,
            mensaje: this.chatInputMessage,
            apellido: this.activeChatContact.apellido,
            idusuario: this.senderID,
          };
          this.activeChat.push(newMsj);
          const contact = this.chatsContacts.find(
            (c) => c.idusuario === this.activeChatContact.id
          );
          if (contact) contact.mensaje = this.chatInputMessage;
          else this.chatsContacts.unshift(newMsj);
          this.chatInputMessage = "";
          this.$nextTick(() => {
            this.scrollToBottomInChatLog();
          });
        }
      });
    },
    onClickChat() {
      this.showChat = true;
      this.newMessages = 0;
    },
    onCloseModal() {
      this.activeChatContact = null;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
.btn-chat {
  right: 90px;
  bottom: 5%;
  z-index: 99;
  position: fixed;
}
.chat-application {
  height: 70vh;
  display: flex;
}
.chat-application .chat-app-window {
  flex: 1;
  height: 100%;
}
.chat-application .sidebar-content {
  height: 70vh;
}
.chat-application .sidebar-content .chat-user-list-wrapper {
  height: 70vh !important;
}
.chat .modal-body {
  padding: 0 !important;
}
.chat-application .chat-app-window .start-chat-area {
  border-radius: 5px !important;
  background-size: 150px !important;
}
.loader {
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
