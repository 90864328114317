<template>
  <div>
    <div>
      <div class="sidebar-content card show">
        <!-- ScrollArea: Chat & Contacts -->

        <div v-if="newChat" class="cntcs">
          <b-button
            variant="flat-success"
            class="btn-icon mr-1"
            @click="onClickBack"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <h4 class="m-0">Contactos</h4>
        </div>
        <b-button
          v-else
          @click="onClickNewChat"
          class="mx-1 mb-1"
          variant="outline-primary"
        >
          Nuevo chat
        </b-button>

        <vue-perfect-scrollbar
          v-if="newChat"
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >
          <!-- Search -->
          <b-input-group style="width: 90%" class="input-group-merge ml-1">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input v-model="searchQuery" placeholder="Buscar..." />
          </b-input-group>

          <div v-if="isLoading" class="ldr">
            <b-spinner label="Loading..." />
          </div>

          <!-- Contacts -->
          <ul v-else class="chat-users-list contact-list media-list">
            <chat-contact
              tag="li"
              v-for="contact in filteredContacts"
              :key="contact.idUsuario"
              :user="contact"
              @click="
                (event) => {
                  event.preventDefault();
                  $emit('open-chat', contact);
                }
              "
            />
          </ul>
        </vue-perfect-scrollbar>

        <!-- Chats -->
        <vue-perfect-scrollbar
          v-else
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >
          <ul class="chat-users-list chat-list media-list">
            <chat-contact
              tag="li"
              :key="contact.idmensaje"
              :user="contact"
              v-for="contact in filteredChatsContacts"
              :class="{ active: activeChatContactId === contact.idusuario }"
              is-chat-contact
              @click="
                (event) => {
                  event.preventDefault();
                  $emit('open-chat', contact);
                }
              "
            />
          </ul>
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BSpinner,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import ChatContact from "./ChatContact.vue";
import { getAuthToken } from "@/auth/utils";
import UserProfileSidebar from "./UserProfileSidebar.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BButton,
    BAvatar,
    BSpinner,
    BFormInput,
    ChatContact,
    BInputGroup,
    BInputGroupPrepend,
    UserProfileSidebar,
    VuePerfectScrollbar,
  },
  props: {
    chatsContacts: {
      type: Array,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newChat: false,
      contacts: [],
      isLoading: false,
      searchQuery: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
    };
  },
  methods: {
    getContacts() {
      this.isLoading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/UsuarioChat",
          body: JSON.stringify({
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.isLoading = false;
          if (res.data.bOk) {
            this.contacts = res.data.lsUsuarios;
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.error = e.message;
          this.isLoading = false;
        });
    },
    onClickNewChat(e) {
      e && e.preventDefault();
      this.newChat = true;
      if (this.contacts.length === 0) this.getContacts();
    },
    onClickBack(e) {
      e && e.preventDefault();
      this.newChat = false;
    },
    searchFilterFunction(contact) {
      return contact.nombre
        .toLowerCase()
        .includes(this.searchQuery.toLowerCase());
    },
  },
  computed: {
    filteredContacts() {
      return this.contacts.filter(this.searchFilterFunction);
    },
    filteredChatsContacts() {
      return this.chatsContacts.filter(this.searchFilterFunction);
    },
  },
};
</script>
<style>
.ldr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cntcs {
  display: flex;
  align-items: center;
}
</style>
