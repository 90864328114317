<template>
  <div v-if="chatData" class="chats">
    <div
      v-for="msj in chatData"
      :key="msj.idMensaje"
      class="chat"
      :class="{
        'chat-left': msj.idusuario !== senderId && msj.idUsuario !== senderId,
      }"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="secondary"
        />
      </div>
      <div class="chat-body">
        <div class="chat-content">
          <p>{{ msj.mensaje }}</p>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="ldrn">
    <b-spinner label="Loading..." />
  </div>
</template>

<script>
import { BAvatar, BSpinner } from "bootstrap-vue";

export default {
  components: {
    BAvatar,
    BSpinner,
  },
  props: {
    chatData: {
      type: Array,
    },
    senderId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>
.ldrn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
