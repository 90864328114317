<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="cartItemsCount"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Carrito de compras
        </h4>
        <b-badge pill variant="light-primary">
          {{ cartItemsCount }}
          Items
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="items.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media v-for="item in items" :key="item.name">
        <template #aside>
          <b-img :src="item.imagen" :alt="item.nombre" rounded width="62px" />
        </template>
        <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="removeItemFromCart(item.id)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.nombre }}
            </b-link>
          </h6>
        </div>

        <div class="cart-item-qty ml-1">
          <b-form-spinbutton
            v-model="item.qty"
            min="1"
            :max="item.stock"
            size="sm"
            @change="updateItemQty(item.id, item.qty)"
          />
        </div>

        <h5 class="cart-item-price">${{ item.precioiva }}</h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li v-if="items.length" class="dropdown-menu-footer">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Total:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">${{ totalAmount }}</h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'apps-e-commerce-checkout' }"
      >
        Realizar compra
      </b-button>
    </li>

    <p v-if="cartItemsCount === 0" class="m-0 p-1 text-center">
      Carrito vacío
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BImg,
  BFormSpinbutton,
  BButton
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton
  },
  directives: {
    Ripple
  },
  watch: {
    $route(to, from) {
      const allowedRoutes = ["apps-e-commerce-checkout", "tienda-cliente"];
      const isAllowedRoute = allowedRoutes.includes(to.name);
      if (!isAllowedRoute) {
        this.$store.commit("appEcommerce/REMOVE_ALL_CART_ITEMS");
        this.$store.commit("appEcommerce/UPDATE_CART_ITEMS_COUNT");
      }
      if (to.name !== "apps-e-commerce-checkout") {
        this.$store.commit("appEcommerce/UPDATE_ES_ENVIO_GRATIS");
        this.$store.dispatch("appEcommerce/updatePaymenTotal");
      }
    }
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false
      }
    };
  },
  computed: {
    ...mapGetters({
      cartItemsCount: "appEcommerce/getCartItemsCount",
      totalAmount: "appEcommerce/getTotalAmount",
      items: "appEcommerce/getCartItems"
    })
  },
  methods: {
    removeItemFromCart(productId) {
      this.$store.commit("appEcommerce/REMOVE_PRODUCT_CART", productId);
      this.$store.commit("appEcommerce/UPDATE_CART_ITEMS_COUNT");
    },
    updateItemQty(productId, qty) {
      this.$store.commit("appEcommerce/UPDATE_PRODUCT_QUANTITY_IN_CART", {
        productId,
        qty
      });
      this.$store.dispatch("appEcommerce/updatePaymenTotal");
    },
    removeAllCartItems() {
      this.$store.commit("appEcommerce/REMOVE_ALL_CART_ITEMS");
      this.$store.commit("appEcommerce/UPDATE_CART_ITEMS_COUNT");
    }
  }
};
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
