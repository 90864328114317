import { canUser } from "@/auth/utils";

export default function useSidebarMenu() {
  const navMenuItems = [
    ...(canUser("dashboard")
      ? [
        {
          title: "Dashboard",
          route: "dashboard",
          icon: "HomeIcon",
        },
      ]
      : []),

    ...(canUser("ordenes")
      ? [
        {
          title: "Ordenes",
          route: "ordenes",
          icon: "SendIcon",
        },
      ]
      : []),

    ...(canUser("kanban")
      ? [
        {
          title: "Mis Ordenes",
          route: "kanban",
          icon: "TrelloIcon",
        },
      ]
      : []),

    ...(canUser("store")
      ? [
        {
          title: "Mi store",
          route: "store",
          icon: "ShoppingBagIcon",
        },
      ]
      : []),

    ...(canUser("market")
      ? [
        {
          title: "Market",
          icon: "BoxIcon",
          children: [
            {
              title: "Productos",
              route: "productosmarket",
              icon: "MenuIcon",
            },
            {
              title: "Carga Masiva",
              route: "actualizacion-masiva-productos",
              icon: "MenuIcon",
            },
          ],
        },
      ]
      : []),

    ...(canUser("mi-menu")
      ? [
        {
          title: "Mi Menu",
          route: "mi-menu",
          icon: "ShoppingCartIcon",
        },
      ]
      : []),

    ...(canUser("cargar-productos")
      ? [
        {
          title: "Cargar Productos",
          route: "cargar-productos",
          icon: "UploadCloudIcon",
        },
      ]
      : []),

    ...(canUser("contenido")
      ? [
        {
          title: "Contenido",
          icon: "SmartphoneIcon",
          children: [
            {
              title: "Home",
              route: "contenido",
              icon: "HomeIcon",
            },
            {
              title: "Menu",
              route: "menu",
              icon: "MenuIcon",
            },
            {
              title: "Categorías",
              route: "categorias",
              icon: "ShoppingBagIcon",
            },
            /*  {
               title: "Categorías de tiendas",
               route: "store-categories",
               icon: "ShoppingBagIcon",
             }, */
          ],
        },
      ]
      : []),

    ...(canUser("picking")
      ? [
        {
          title: "Picking",
          route: "picking",
          icon: "PackageIcon",
        },
      ]
      : []),

    ...(canUser("sampling")
      ? [
        {
          title: "Sampling",
          icon: "GiftIcon",
          children: [
            {
              title: "Lista",
              route: "sampling",
              icon: "ListIcon",
            },
            {
              title: "Productos",
              route: "sampling-productos",
              icon: "ShoppingBagIcon",
            },
          ],
        },
      ]
      : []),

    ...(canUser("marcas")
      ? [
        {
          title: "Marcas",
          route: "marcas",
          icon: "BriefcaseIcon",
        },
      ]
      : []),

    ...(canUser("call-center")
      ? [
        {
          title: "Call center",
          route: "call-center",
          icon: "PhoneCallIcon",
        },
      ]
      : []),

    ...(canUser("novedades-informativas")
      ? [
        {
          title: "Novedades informativas",
          route: "novedades-informativas",
          icon: "RssIcon",
        },
      ]
      : []),

    ...(canUser("usuarios")
      ? [
        {
          title: "Usuarios",
          route: "usuarios",
          icon: "UserIcon",
        },
      ]
      : []),

    ...(canUser("configuracion")
      ? [
        {
          title: "Configuration",
          icon: "ToolIcon",
          children: [
            {
              title: "ivaFeriado",
              route: "iva-feriado",
              icon: "MenuIcon",
            },
          ],
        },
      ]
      : []),

    ...(canUser("reportes")
      ? [
        {
          title: "Reportes",
          route: "reportes",
          icon: "BookIcon",
        },
      ]
      : []),

    ...(canUser("clientes")
      ? [
        {
          title: "Clientes",
          icon: "UsersIcon",
          route: "clientes",
        },
      ]
      : []),

    ...(canUser("promociones")
      ? [
        {
          title: "Promo",
          icon: "TagIcon",
          children: [
            {
              title: "Codes",
              route: "promocodes",
              icon: "HashIcon",
            },
            {
              title: "Tarjeta",
              route: "promotarjetas",
              icon: "CreditCardIcon",
            },
            {
              title: "Bines",
              route: "bines",
              icon: "BriefcaseIcon",
            },
          ],
        },
      ]
      : []),

    ...(canUser("notificaciones-push")
      ? [
        {
          title: "Notificaciones Push",
          route: "notificaciones-push",
          icon: "MessageSquareIcon",
        },
      ]
      : []),

    ...(canUser("easycreditos")
      ? [
        {
          title: "EasyCreditos",
          route: "easycreditos",
          icon: "DollarSignIcon",
        },
      ]
      : []),

    ...(canUser("bodegas")
      ? [
        {
          title: "Bodegas",
          route: "bodegas",
          icon: "TruckIcon",
        },
      ]
      : []),

    ...(canUser("banner-invasivo")
      ? [
        {
          title: "Banner Invasivo",
          icon: "InfoIcon",
          route: "banner-invasivo",
        },
      ]
      : []),

    ...(canUser("cache")
      ? [
        {
          title: "Cache",
          icon: "CpuIcon",
          route: "cache-module",
        },
      ]
      : []),

    ...(canUser("subscription")
      ? [
        {
          title: "Subscriptions",
          icon: "StarIcon",
          children: [
            {
              title: "Plans",
              route: "subscription-plans",
              icon: "AlignJustifyIcon",
            },
            {
              title: "Options",
              route: "subscription-options",
              icon: "AlignJustifyIcon",
            },
            {
              title: "Offers",
              route: "subscription-offers",
              icon: "AlignJustifyIcon",
            },
            {
              title: "Applications",
              route: "subscription-applications",
              icon: "AlignJustifyIcon",
            },
            {
              title: "Products",
              route: "subscription-products",
              icon: "AlignJustifyIcon",
            },
          ],
        },
      ]
      : []),
    ...(canUser("tienda")
      ? [
        {
          title: "Tienda",
          route: "tienda",
          icon: "ShoppingCartIcon"
        },
      ]
      : []),
  ];
  return {
    navMenuItems,
  };
}
