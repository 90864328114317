<template>
  <div></div>
</template>

<script>
import store from "@/store";
import { getDefaultStore } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationInvertedContent.vue";
const event = (e) => { };
const soundNotification = new Audio(require("../../assets/sounds/alert.mp3"));

export default {
  components: {
    ToastificationContent,
  },
  data() {
    return {
      socket: null,
      storeID: 0,
      unwatch: null,
      retryTimeout: null,
      connecting: false,
      isConnected: false,
    };
  },
  beforeMount() {
    this.storeID = getDefaultStore();
  },
  mounted() {
    const body = document.body;
    body.addEventListener("click", event);
    body.click();
    this.socketConnection();
    this.onStoreSelectedChange();
  },
  beforeDestroy() {
    this.socket && this.socket.close(3999, "ComponentDestroy");
    this.unwatch();
    const body = document.body;
    body.removeEventListener("click", event);
  },
  methods: {
    socketConnection() {
      if (this.socket === null) {
        this.connecting = true;
        this.socket = new WebSocket(this.$store.state.app.socketURL);
        this.socket.onopen = this.onSocketOpen;
        this.socket.onclose = this.onSocketClose;
        this.socket.onmessage = this.onNewNotification;
      }
    },
    onSocketOpen(event) {
      this.connecting = false;
      this.isConnected = true;
      if (this.socket.readyState === WebSocket.OPEN) {
        this.sendMessage(
          JSON.stringify({
            event: "/ORDER/NEW",
            payload: { empresaAsociadaID: this.storeID },
          })
        );
      }
    },
    onSocketClose(event) {
      this.connecting = false;
      this.isConnected = false;
      if (event.code === 3999 || event.code === 4000) {
        // TODO: Do nothing or handle
      } else {
        this.socket = null;
        console.log(`Socket Disconnected, retrying connect to ${this.$store.state.app.socketURL} in 5s`);
        clearTimeout(this.retryTimeout);
        this.retryTimeout = setTimeout(this.socketConnection, 5000);
      }
    },
    onNewNotification(event) {
      const message = JSON.parse(event.data);
      const newOrder = message.payload;
      switch (message.event) {
        case "/ORDER/NEW":
          const timeout = 1000 * 60;
          if (newOrder.fechaprogramada !== null && newOrder.tipoentrega === 'PG') {
            /**  
            * [P380]: 
            * Se solicito que no suene ni alerte las ordenes programadas antes de la hora programada
            */
          } else {
            this.$toast.success(
              {
                component: ToastificationContent,
                props: {
                  icon: "BellIcon",
                  text: `Se acaba de crear la orden #${newOrder.idorden}.`,
                  title: "Nueva Orden",
                  draggable: true,
                  closeButton: "button",
                  closeOnClick: true,
                  pauseOnHover: true,
                  hideProgressBar: true,
                  draggablePercent: 0.6,
                  pauseOnFocusLoss: false,
                  showCloseButtonOnHover: true,
                },
              },
              {
                timeout,
              }
            );
            soundNotification.muted = false;
            try {
              soundNotification.play();
            } catch (error) {
              //TODO: Handle
            }
            store.commit("app/UPDATE_LAST_SOCKET_ORDER", newOrder);
          }
          break;
        case "/ORDER/STATE":
          store.commit("app/UPDATE_LAST_SOCKET_ORDER", newOrder);
          break;
        default:
          console.log(message);
          break;
      }
    },
    sendMessage(message) {
      this.socket.send(message);
    },
    onStoreSelectedChange() {
      this.unwatch = this.$store.watch(
        (_, g) => {
          return g["app/storeSelected"];
        },
        (storeID, __) => {
          this.storeID = storeID;
          this.socket.close(4000, "ChangeStore");
          setTimeout(() => {
            this.socket = null;
            this.socketConnection();
          }, 500);
        }
      );
    },
  },
};
</script>
