var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item-dropdown', {
    staticClass: "dropdown-notification mr-25",
    attrs: {
      "menu-class": "dropdown-menu-media",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "text-body",
          attrs: {
            "badge": _vm.totalNotificaciones,
            "badge-classes": "bg-danger",
            "icon": "BellIcon",
            "size": "21"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('li', {
    staticClass: "dropdown-menu-header"
  }, [_c('div', {
    staticClass: "dropdown-header d-flex"
  }, [_c('h4', {
    staticClass: "notification-title mb-0 mr-auto"
  }, [_vm._v("Información")]), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.totalNotificaciones) + " Nuevas ")])], 1)]), _vm.totalNotificaciones > 0 ? _c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container media-list scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "li"
    }
  }, _vm._l(_vm.notifications, function (notification) {
    return _c('b-link', {
      key: notification.idNovedades,
      attrs: {
        "href": notification.ruta
      }
    }, [_c('div', [_c('b-media', {
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [_c('b-avatar', {
            attrs: {
              "rounded": "",
              "variant": _vm.tipoVariant(notification.tipo),
              "size": "34"
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": _vm.tipoImage(notification.tipo),
              "size": "18"
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "lnk"
    }, [_c('div', [_c('p', {
      staticClass: "media-heading"
    }, [_c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(notification.nombre) + " ")])]), _c('small', {
      staticClass: "notification-text"
    }, [_vm._v(_vm._s(notification.tipo))])]), _c('label', {
      staticClass: "notification-text"
    }, [_vm._v(_vm._s(notification.tipo === "Video" && notification.externo ? "Abrir" : "Descargar"))])])])], 1)]);
  }), 1) : _c('b-alert', {
    staticClass: "m-1",
    attrs: {
      "show": "",
      "variant": "primary"
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }), _c('span', {
    staticClass: "ml-25"
  }, [_vm._v("No hay Novedades.")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }