var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-nav-item', {
    on: {
      "click": _vm.onClickChat
    }
  }, [_c('feather-icon', {
    staticClass: "text-body",
    attrs: {
      "badge": _vm.newMessages,
      "badge-classes": "bg-danger",
      "icon": "MessageCircleIcon",
      "size": "21"
    }
  })], 1), _c('b-modal', {
    attrs: {
      "size": "lg",
      "title": "Chats",
      "centered": "",
      "hide-footer": "",
      "modal-class": "chat"
    },
    on: {
      "hidden": _vm.onCloseModal
    },
    model: {
      value: _vm.showChat,
      callback: function callback($$v) {
        _vm.showChat = $$v;
      },
      expression: "showChat"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "loader"
  }, [_c('b-spinner')], 1) : _c('div', {
    staticClass: "chat-application"
  }, [_c('chat-left-sidebar', {
    attrs: {
      "chats-contacts": _vm.chatsContacts,
      "profile-user-data": _vm.profileUserData,
      "active-chat-contact-id": _vm.activeChatContact ? _vm.activeChatContact.id : null,
      "profile-user-minimal-data": _vm.profileUserDataMinimal
    },
    on: {
      "open-chat": _vm.openChatOfContact
    }
  }), _c('section', {
    staticClass: "chat-app-window"
  }, [!_vm.activeChatContact ? _c('div', {
    staticClass: "start-chat-area"
  }, [_c('div', {
    staticClass: "mb-1 start-chat-icon"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MessageSquareIcon",
      "size": "56"
    }
  })], 1)]) : _c('div', {
    staticClass: "active-chat"
  }, [_c('div', {
    staticClass: "chat-navbar"
  }, [_c('header', {
    staticClass: "chat-header"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "sidebar-toggle d-block d-lg-none mr-1"
  }), _c('b-avatar', {
    staticClass: "mr-1 cursor-pointer badge-minimal",
    attrs: {
      "size": "36",
      "badge": ""
    }
  }), _c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.activeChatContact.nombre) + " " + _vm._s(_vm.activeChatContact.apellido) + " ")])], 1)])]), _c('vue-perfect-scrollbar', {
    ref: "refChatLogPS",
    staticClass: "user-chats scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_c('chat-log', {
    attrs: {
      "chat-data": _vm.activeChat,
      "senderId": _vm.senderID
    }
  })], 1), _c('div', {
    staticClass: "chat-app-form"
  }, [_c('b-input-group', {
    staticClass: "input-group-merge form-send-message mr-1"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Escribe tu mensaje aqui"
    },
    model: {
      value: _vm.chatInputMessage,
      callback: function callback($$v) {
        _vm.chatInputMessage = $$v;
      },
      expression: "chatInputMessage"
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.isSending
    },
    on: {
      "click": _vm.sendMessage
    }
  }, [_vm._v(" Enviar ")])], 1)], 1)])], 1)]), _c('socket', {
    attrs: {
      "onNewMessage": _vm.onNewMessage
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }