<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Tipo -->
    <b-form-checkbox v-if="isDashboard && isSuperUser()" switch inline @change="onChangeType" v-model="isMarket">
      Market
    </b-form-checkbox>

    <!-- Tiendas -->
    <div class="storeSelect" v-if="showStoreSelect">
      <b-spinner label="Loading..." v-if="loading" />
      <v-select v-if="!loading && storesAllowed.length > 1" label="nombre" v-model="storeSelected"
        :options="allStoresAllowed" :clearable="false" @option:selected="onChangeStore" />
    </div>

    <!-- Bodegas -->
    <div class="storeSelect" v-if="showWarehouseSelect">
      <b-spinner label="Loading..." v-if="loading" />
      <v-select v-if="!loading && warehousesAllowed.length > 1" label="nombre" v-model="warehouseSelected"
        :options="allWarehousesAllowed" :clearable="false" @option:selected="onChangeWarehouse" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <chat />
      <notification-dropdown />
      <cart-dropdown />
      <user-dropdown />
    </b-navbar-nav>

    <!-- sockets -->
    <socket-market v-if="listenSocketMarket" />
    <socket-empresa-asociada v-if="listenSocketEats" />
  </div>
</template>

<script>
import { BLink, BSpinner, BNavbarNav, BFormCheckbox } from "bootstrap-vue";
import Chat from "../../../../views/chat/Chat.vue";
import store from "@/store";
import vSelect from "vue-select";
import DarkToggler from "./components/DarkToggler.vue";
import UserDropdown from "./components/UserDropdown.vue";
import SocketMarket from "../../../../views/socket/Market.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import CartDropdown from "./components/CartDropdown.vue";
import SocketEmpresaAsociada from "../../../../views/socket/EmpresaAsociada.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  isSuperUser,
  isRoleMarket,
  getAuthToken,
  getDefaultStore,
  setDefaultStore,
  getDefaultWarehouse,
  setDefaultWarehouse,
} from "@/auth/utils";

export default {
  components: {
    Chat,
    BLink,
    vSelect,
    BSpinner,
    BNavbarNav,
    DarkToggler,
    SocketMarket,
    UserDropdown,
    BFormCheckbox,
    NotificationDropdown,
    SocketEmpresaAsociada,
    ToastificationContent,
    CartDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  data() {
    return {
      loading: true,
      showAll: false,
      isMarket: true,
      isDashboard: false,
      storesAllowed: [],
      storeSelected: null,
      showStoreSelect: false,
      listenSocketEats: false,
      warehousesAllowed: [],
      warehouseSelected: null,
      listenSocketMarket: false,
      showWarehouseSelect: false,
    };
  },
  beforeMount() {
    this.isMarket = isRoleMarket();
    this.isDashboard = this.$route.name === "dashboard";
  },
  mounted() {
      this.loadSelect(this.$route.name).then(() => {
        this.listenStoresMods();
        if(this.$store.state.app.fromLogin) {
          store.commit("app/SET_FROM_LOGIN",false);
          if (this.isDashboard) this.updateView();
        }
      });
  },
  beforeDestroy() {
    this.unwatchStoresMods && this.unwatchStoresMods();
  },
  watch: {
    $route(to, prev) {
      this.isDashboard = to.name === "dashboard";
      this.loadSelect(to.name).then(() => {
        const storeSelected = this.storesAllowed.find((s) => s.seleccionada);
        if (storeSelected === undefined) {
          this.storeSelected = this.storesAllowed[0];
        }
        const warehouseSelected = this.warehousesAllowed.find(
          (s) => s.seleccionada
        );
        if (warehouseSelected === undefined) {
          this.warehouseSelected = this.warehousesAllowed[0];
        }
        if (this.isDashboard)
          setTimeout(() => {
            this.updateView();
          }, 500);
      });
    },
  },
  methods: {
    isSuperUser,
    onChangeType(value) {
      store.commit("app/UPDATE_TOGGLE_MARKET", value);
      this.loadSelect(this.$route.name).then(this.updateView);
    },
    updateView() {
      if (this.isMarket) {
        store.commit("app/UPDATE_WAREHOUSE_SELECTED", null);
        this.$nextTick(() => {
          if (this.warehouseSelected && this.warehouseSelected.id > 0) {
            store.commit(
              "app/UPDATE_WAREHOUSE_SELECTED",
              this.warehouseSelected.id
            );
          }
        });
      } else {
        store.commit("app/UPDATE_STORE_SELECTED", null);
        this.$nextTick(() => {
          if (this.storeSelected && this.storeSelected.id > 0) {
            store.commit("app/UPDATE_STORE_SELECTED", this.storeSelected.id);
          }
        });
      }
    },
    loadSelect(route) {
      return new Promise(async (resolve) => {
        switch (route) {
          case "store":
          case "kanban":
          case "mi-menu":
          case "cargar-productos":
            this.showAll = false;
            this.showStoreSelect = true;
            this.showWarehouseSelect = false;
            break;
          case "dashboard":
            this.showAll = true;
            if (isSuperUser()) {
              if (this.isMarket) {
                this.showStoreSelect = false;
                this.showWarehouseSelect = true;
              } else {
                this.showStoreSelect = true;
                this.showWarehouseSelect = false;
              }
            } else {
              if (isRoleMarket()) {
                this.showStoreSelect = false;
                this.showWarehouseSelect = true;
              } else {
                this.showStoreSelect = true;
                this.showWarehouseSelect = false;
              }
            }
            break;
          case "picking":
            this.showStoreSelect = false;
            this.showWarehouseSelect = true;
            break;
          default:
            this.showAll = false;
            this.showStoreSelect = false;
            this.showWarehouseSelect = false;
            break;
        }
        if (this.showStoreSelect) {
          if (this.storesAllowed.length === 0) {
            await this.getStoresAllowed();
            store.commit("app/UPDATE_STORE_SELECTED", this.storeSelected.id);
          }
          this.initSockets("EATS");
        }
        if (this.showWarehouseSelect) {
          if (this.warehousesAllowed.length === 0) {
            await this.getWarehousesAllowed();
          }
          this.initSockets("MARKET");
        }

        if (!this.showAll && this.storeSelected?.id === 0) {
          this.storeSelected = this.storesAllowed.find(
            (s) => s.id === getDefaultStore()
          );
        }
        resolve();
      });
    },
    initSockets(comercio) {
      if (comercio === "EATS" && this.storeSelected) {
        this.listenSocketEats = true;
        this.listenSocketMarket = false;
      }
      if (comercio === "MARKET" && this.warehouseSelected) {
        this.listenSocketEats = false;
        this.listenSocketMarket = true;
      }
    },
    onChangeStore() {
      if (this.isDashboard && this.storeSelected.id === 0) {
        store.commit("app/UPDATE_STORE_SELECTED", this.storeSelected.id);
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: "BellIcon",
            text: `Se seleccionó todas las tiendas`,
            title: "Éxito",
          },
        });
      } else {
        this.loading = true;
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/UserStoresSelect",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
              empresa_asociada: this.storeSelected.id,
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              store.commit("app/UPDATE_STORE_SELECTED", this.storeSelected.id);
              setDefaultStore(this.storeSelected.id);
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "BellIcon",
                  text: `Se selecciono la tienda [${this.storeSelected.nombre}] como predeterminada`,
                  title: "Éxito",
                },
              });
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.error = e.message;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onChangeWarehouse() {
      if (this.isDashboard) {
        store.commit(
          "app/UPDATE_WAREHOUSE_SELECTED",
          this.warehouseSelected.id
        );
      } else {
        this.loading = true;
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/UserBodegaSelect",
            body: JSON.stringify({
              IDBodega: this.warehouseSelected.id,
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              store.commit(
                "app/UPDATE_WAREHOUSE_SELECTED",
                this.warehouseSelected.id
              );
              setDefaultWarehouse(this.warehouseSelected.id);
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "BellIcon",
                  text: `Se selecciono la bodega [${this.warehouseSelected.nombre}] como predeterminada`,
                  title: "Éxito",
                },
              });
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.error = e.message;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getStoresAllowed() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/UserStores",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.storesAllowed = res.data.lsTiendas;
              this.storeSelected = res.data.lsTiendas.find(
                (s) => s.seleccionada
              );
              if (res.data.lsTiendas.length > 0 && !this.storeSelected) {
                this.storeSelected = res.data.lsTiendas[0];
                if (!this.isDashboard) this.onChangeStore();
              }
              if (getDefaultStore() !== this.storeSelected.id)
                setDefaultStore(this.storeSelected.id);
              store.commit("app/UPDATE_STORES_CHANGED", false);
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.error = e.message;
            reject();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    getWarehousesAllowed() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/UserBodega",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.warehousesAllowed = res.data.lsBodegas;
              this.warehouseSelected = res.data.lsBodegas.find(
                (s) => s.seleccionada
              );
              if (!this.warehouseSelected && res.data.lsBodegas.length > 0) {
                this.warehouseSelected = res.data.lsBodegas[0];
                if (!this.isDashboard) this.onChangeWarehouse();
              }
              if (getDefaultWarehouse() !== this.warehouseSelected.id)
                setDefaultWarehouse(this.warehouseSelected.id);
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.error = e.message;
            reject();
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    listenStoresMods() {
      this.unwatchStoresMods = store.watch(
        (_, g) => {
          return g["app/hasStoresChanged"];
        },
        (value) => {
          if (value) {
            this.getStoresAllowed();
          }
        }
      );
    },
  },
  computed: {
    allStoresAllowed() {
      if (this.showAll) {
        return [{ id: 0, nombre: "Todas" }, ...this.storesAllowed];
      } else {
        return this.storesAllowed;
      }
    },
    allWarehousesAllowed() {
      if (this.showAll) {
        return [{ id: 0, nombre: "Todas" }, ...this.warehousesAllowed];
      } else {
        return this.warehousesAllowed;
      }
    },
  },
};
</script>

<style lang="scss">
.storeSelect {
  width: 300px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>