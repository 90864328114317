<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="totalNotificaciones"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Información</h4>
        <b-badge pill variant="light-primary">
          {{ totalNotificaciones }} Nuevas
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="totalNotificaciones > 0"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.idNovedades"
        :href="notification.ruta"
      >
        <div>
          <b-media>
            <template #aside>
              <b-avatar
                rounded
                :variant="tipoVariant(notification.tipo)"
                size="34"
              >
                <feather-icon :icon="tipoImage(notification.tipo)" size="18" />
              </b-avatar>
            </template>
            <div class="lnk">
              <div>
                <p class="media-heading">
                  <span class="font-weight-bolder">
                    {{ notification.nombre }}
                  </span>
                </p>
                <small class="notification-text">{{ notification.tipo }}</small>
              </div>
              <label class="notification-text">{{
                notification.tipo === "Video" && notification.externo
                  ? "Abrir"
                  : "Descargar"
              }}</label>
            </div>
          </b-media>
        </div>
      </b-link>
    </vue-perfect-scrollbar>
    <b-alert show v-else variant="primary" class="m-1">
      <div class="alert-body">
        <feather-icon class="mr-25" icon="CheckCircleIcon" />
        <span class="ml-25"
          >No hay Novedades.</span
        >
      </div>
    </b-alert>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BLink,
  BBadge,
  BMedia,
  BAlert,
  BAvatar,
  BButton,
  BMediaAside,
  BFormCheckbox,
  BNavItemDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { getAuthToken } from "@/auth/utils";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BLink,
    BBadge,
    BMedia,
    BAlert,
    BAvatar,
    BButton,
    BMediaAside,
    BFormCheckbox,
    BNavItemDropdown,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: [],
      totalNotificaciones: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.getNotificacionesData();
    }, 2000);
  },
  methods: {
    tipoImage(tipo) {
      switch (tipo) {
        case "Video":
          return "YoutubeIcon";
        case "Documento":
          return "FileTextIcon";
        case "Contabilidad":
          return "BookIcon";
        default:
          return "YoutubeIcon";
      }
    },
    tipoVariant(tipo) {
      switch (tipo) {
        case "Video":
          return "light-warning";
        case "Documento":
          return "light-info";
        case "Contabilidad":
          return "light-success";
        default:
          return "light-danger";
      }
    },
    getNotificacionesData() {
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/NovedadInformativa",
          body: JSON.stringify({
            opt: "A",
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            this.notifications = res.data.lsNovedades;
            this.totalNotificaciones = res.data.lsNovedades.length;
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = e.message;
        });
    },
  },
};
</script>
<style>
.lnk {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>