var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tag, _vm._g({
    tag: "component"
  }, _vm.$listeners), [_c('b-avatar', {
    staticClass: "badge-minimal",
    attrs: {
      "size": _vm.isChatContact ? 42 : 30,
      "badge": _vm.isChatContact,
      "variant": "secondary",
      "badge-variant": "primary"
    }
  }), _c('div', {
    staticClass: "chat-info flex-grow-1"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.user.nombre) + " " + _vm._s(_vm.user.apellido) + " ")]), _c('p', {
    staticClass: "card-text text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.isChatContact ? _vm.user.mensaje : _vm.user.about) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }