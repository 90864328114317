var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.chatData ? _c('div', {
    staticClass: "chats"
  }, _vm._l(_vm.chatData, function (msj) {
    return _c('div', {
      key: msj.idMensaje,
      staticClass: "chat",
      class: {
        'chat-left': msj.idusuario !== _vm.senderId && msj.idUsuario !== _vm.senderId
      }
    }, [_c('div', {
      staticClass: "chat-avatar"
    }, [_c('b-avatar', {
      staticClass: "avatar-border-2 box-shadow-1",
      attrs: {
        "size": "36",
        "variant": "secondary"
      }
    })], 1), _c('div', {
      staticClass: "chat-body"
    }, [_c('div', {
      staticClass: "chat-content"
    }, [_c('p', [_vm._v(_vm._s(msj.mensaje))])])])]);
  }), 0) : _c('div', {
    staticClass: "ldrn"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }