<template>
  <component :is="tag" v-on="$listeners">
    <b-avatar
      class="badge-minimal"
      :size="isChatContact ? 42 : 30"
      :badge="isChatContact"
      variant="secondary"
      badge-variant="primary"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ user.nombre }}
        {{ user.apellido }}
      </h5>
      <p class="card-text text-truncate">
        {{ isChatContact ? user.mensaje : user.about }}
      </p>
    </div>
    <!--  <div
      v-if="isChatContact"
      class="chat-meta text-nowrap"
    >
      <small class="float-right mb-25 chat-time">13:20</small>
      <b-badge
        v-if="user.chat.unseenMsgs"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.chat.unseenMsgs }}
      </b-badge>
    </div> -->
  </component>
</template>

<script>
import { BBadge, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BBadge,
    BAvatar,
  },
  props: {
    tag: {
      type: String,
      default: "div",
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
};
</script>

<style>
</style>
