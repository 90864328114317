import axios from '@axios';
import { getAuthToken } from "@/auth/utils";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChats(ctx, { middlewareURL }) {
      return new Promise((resolve, reject) => {
        axios
          .post(middlewareURL, {
            path: "/Administracion/ChatConversaciones",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/users/profile-user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChat(ctx, { userId, middlewareURL }) {
      return new Promise((resolve, reject) => {
        axios
          .post(middlewareURL, {
            path: "/Administracion/ChatOpenConversacion",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
              IDUsuarioChat: userId
            }),
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, { message, IDConversacion, IDUsuarioChat, middlewareURL }) {
      return new Promise((resolve, reject) => {
        axios
          .post(middlewareURL, {
            path: "/Administracion/ChatMensajesCrear",
            body: JSON.stringify({
              Mensaje: message,
              tokenSesion: getAuthToken(),
              IDUsuarioChat: IDUsuarioChat,
              IDConversacion: IDConversacion,
            }),
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
  },
}
