<template>
  <div></div>
</template>

<script>
import store from "@/store";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationInvertedContent.vue";
import { getDefaultWarehouse } from "@/auth/utils";
const event = (e) => {};
const soundNotification = new Audio(require("../../assets/sounds/alert.mp3"));

export default {
  components: {
    ToastificationContent,
  },
  data() {
    return {
      socket: null,
      unwatch: null,
      connecting: false,
      warehouseID: 0,
      isConnected: false,
      retryTimeout: null,
    };
  },
  beforeMount() {
    this.warehouseID = getDefaultWarehouse();
  },
  mounted() {
    const body = document.body;
    body.addEventListener("click", event);
    body.click();
    this.socketConnection();
    this.onWarehouseSelectedChange();
  },
  beforeDestroy() {
    this.socket && this.socket.close(3999, "ComponentDestroy");
    this.unwatch();
    document.body.removeEventListener("click", event);
  },
  methods: {
    socketConnection() {
      if (this.socket === null) {
        this.connecting = true;
        this.socket = new WebSocket(this.$store.state.app.socketURL);
        this.socket.onopen = this.onSocketOpen;
        this.socket.onclose = this.onSocketClose;
        this.socket.onmessage = this.onNewNotification;
      }
    },
    onSocketOpen(event) {
      this.connecting = false;
      this.isConnected = true;
      this.sendMessage(
        JSON.stringify({
          event: "/ORDER/NEW",
          payload: { bodegaID: this.warehouseID },
        })
      );
    },
    onSocketClose(event) {
      this.connecting = false;
      this.isConnected = false;
      if (event.code === 3999 || event.code === 4000) {
        // TODO: Do nothing or handle
      } else {
        this.socket = null;
        this.retryTimeout = setTimeout(this.socketConnection, 10000);
      }
    },
    onNewNotification(event) {
      const message = JSON.parse(event.data);
      const newOrder = message.payload;
      store.commit("app/UPDATE_LAST_SOCKET_ORDER", newOrder);
      if (newOrder.estadoorden === "PG" && newOrder.estadopicking === "CR") {
        const timeout = 1000 * 60 * 30;
        this.$toast.success(
          {
            component: ToastificationContent,
            props: {
              icon: "BellIcon",
              text: `Se acaba de crear la orden #${newOrder.idorden}.`,
              title: "Nueva Orden",
              draggable: true,
              closeButton: "button",
              closeOnClick: true,
              pauseOnHover: true,
              hideProgressBar: true,
              draggablePercent: 0.6,
              pauseOnFocusLoss: true,
              showCloseButtonOnHover: false,
            },
          },
          {
            timeout,
          }
        );
        soundNotification.muted = false;
        soundNotification.play();
      }
    },
    sendMessage(message) {
      this.socket.send(message);
    },
    onWarehouseSelectedChange() {
      this.unwatch = this.$store.watch(
        (_, g) => {
          return g["app/warehouseSelected"];
        },
        (warehouseID, __) => {
          if (warehouseID && this.warehouseID !== warehouseID && !this.connecting) {
            this.warehouseID = warehouseID;
            if (this.socket && this.isConnected)
              this.socket.close(4000, "ChangeWarehouse");
            setTimeout(() => {
              this.socket = null;
              this.socketConnection();
            }, 500);
          }
        }
      );
    },
  },
};
</script>
