<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.nombreUsuario }}
        </p>
        <span class="user-status">{{ roleName }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BAvatar,
  BDropdownItem,
  BDropdownDivider,
  BNavItemDropdown,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import { getUserData, getRoleName } from "@/auth/utils";

export default {
  components: {
    BAvatar,
    BDropdownItem,
    BNavItemDropdown,
    BDropdownDivider,
  },
  data() {
    return {
      roleName: getRoleName(),
      userData: getUserData(),
      avatarText,
    };
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Redirect to login page
      this.$router.replace({ name: "auth-login" });
    },
  },
};
</script>
